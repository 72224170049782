import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import AdminNavbar from "./AdminNavbar";
import Icon from "@material-tailwind/react/Icon";
import PropsType from "prop-types";

Sidebar.propTypes = {
  permision: PropsType.object,
};

export default function Sidebar({ permision }) {
  const [showSidebar, setShowSidebar] = useState("-left-64");
  const {
    hasAdminPermission,
    hasProductPermission,
    hasEditorPermission,
    hasOrderPermission,
    hasMarketingPermission,
  } = permision || {};

  return (
    <>
      <AdminNavbar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <div
        className={`h-screen fixed top-0 md:left-0 ${showSidebar} overflow-y-auto flex-row flex-nowrap overflow-hidden shadow-xl bg-white w-64 z-10 py-4 px-6 transition-all duration-300`}
      >
        <div className="flex-col items-stretch min-h-full flex-nowrap px-0 relative">
          <a
            href="https://vietkho.vn"
            target="_blank"
            rel="noreferrer"
            className="flex items-center gap-4 text-sm font-light py-3"
          >
            <Icon name="home" size="2xl" />
            VietKho&apos;s CMS
          </a>
          {/* <a
            href="/"
            target="_blank"
            rel="noreferrer"
            className="mt-2 text-center w-full inline-block"
          >
            <Icon name="home" size="2xl" /> Doseco&apos;s CMS
          </a> */}

          <div className="flex flex-col">
            <hr className="my-4 min-w-full" />

            <ul className="flex-col min-w-full flex list-none">
              {(hasAdminPermission || hasOrderPermission) && <li className="rounded-lg mb-4">
                <NavLink
                  to="/"
                  exact
                  className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                  activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                >
                  <Icon name="dashboard" size="2xl" />
                  Dashboard
                </NavLink>
              </li>}
              {/* {hasAdminPermission && (
                <li className="rounded-lg mb-2 text-gray-700">
                  <NavLink
                    to="/user"
                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                    activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                  >
                    <Icon name="group" size="2xl" />
                    Quản lý Users
                  </NavLink>
                </li>
              )} */}
              {hasAdminPermission && (
                <li className="rounded-lg mb-2 text-gray-700">
                  <NavLink
                    to="/account"
                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                    activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                  >
                    <Icon name="group" size="2xl" />
                    Quản lý Tài khoản
                  </NavLink>
                </li>
              )}
              {hasOrderPermission && (
                <li className="rounded-lg mb-2 text-gray-700">
                  <a href="#">Đơn hàng</a>
                  <ul>
                    <li>
                      <NavLink
                        to="/promotion"
                        className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                        activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                      >
                        <Icon name="redeem" size="2xl" />
                        Khuyến mại
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to="/order"
                        className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                        activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                      >
                        <Icon name="shopping_cart" size="2xl" />
                        Orders
                      </NavLink>
                    </li>
                  </ul>
                </li>
              )}
              {hasMarketingPermission && (
                <li className="rounded-lg mb-2 text-gray-700">
                  <a href="#">SEO</a>
                  <ul>
                    <li>
                      <NavLink
                        to="/seo"
                        className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                        activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                      >
                        <Icon name="branding_watermark" size="2xl" />
                        Thiết lập SEO
                      </NavLink>
                    </li>
                  </ul>
                </li>
              )}
              {/* <li className="rounded-lg mb-2">
                <NavLink
                  to="/content"
                  className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                  activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                >
                  <Icon name="source" size="2xl" />
                  Nội dung
                </NavLink>
              </li>
              <li className="rounded-lg mb-2">
                <NavLink
                  to="/config"
                  className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                  activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                >
                  <Icon name="settings" size="2xl" />
                  Cấu hình
                </NavLink>
              </li> */}
              {(hasEditorPermission || hasMarketingPermission) && (
                <li className="rounded-lg mb-2 ">
                  <a href="#">Website</a>
                  <ul>
                    {hasEditorPermission && (
                      <li>
                        <NavLink
                          to="/setting"
                          className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                          activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                        >
                          <Icon name="settings" size="2xl" />
                          Settings
                        </NavLink>
                      </li>
                    )}

                    {(hasEditorPermission || hasMarketingPermission) && (
                      <li>
                        <NavLink
                          to="/post"
                          className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                          activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                        >
                          <Icon name="post_add" size="2xl" />
                          Bài viết
                        </NavLink>
                      </li>
                    )}

                    {hasEditorPermission && (
                      <li>
                        <NavLink
                          to="/banner"
                          className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                          activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                        >
                          <Icon name="ad_units" size="2xl" />
                          Banners
                        </NavLink>
                      </li>
                    )}

                    {hasEditorPermission && (
                      <li>
                        <NavLink
                          to="/video"
                          className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                          activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                        >
                          <Icon name="duo" size="2xl" />
                          Videos
                        </NavLink>
                      </li>
                    )}

                    {hasEditorPermission && (
                      <li>
                        <NavLink
                          to="/feedback"
                          className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                          activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                        >
                          <Icon name="thumb_up_alt" size="2xl" />Ý kiến khách
                          hàng
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </li>
              )}
              {(hasProductPermission || hasMarketingPermission) && (
                <li className="rounded-lg mb-2 text-gray-700">
                  <a href="#">Sản phẩm</a>
                  <ul>
                    {hasProductPermission && (
                      <li>
                        <NavLink
                          to="/brand"
                          exact
                          className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                          activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                        >
                          <Icon name="beenhere" size="2xl" />
                          Thương hiệu
                        </NavLink>
                      </li>
                    )}

                    {hasProductPermission && (
                      <li>
                        <NavLink
                          to="/category"
                          exact
                          className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                          activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                        >
                          <Icon name="category" size="2xl" />
                          Danh mục
                        </NavLink>
                      </li>
                    )}

                    {(hasProductPermission || hasMarketingPermission) && (
                      <li>
                        <NavLink
                          to="/group"
                          exact
                          className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                          activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                        >
                          <Icon name="inventory" size="2xl" />
                          Nhóm Sản phẩm
                        </NavLink>
                      </li>
                    )}

                    {(hasProductPermission || hasMarketingPermission) && (
                      <li>
                        <NavLink
                          to="/product"
                          className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                          activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                        >
                          <Icon name="sell" size="2xl" />
                          DS Sản phẩm
                        </NavLink>
                      </li>
                    )}

                    {hasProductPermission && (
                      <li>
                        <NavLink
                          to="/comment"
                          className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                          activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                        >
                          <Icon name="forum" size="2xl" />
                          DS Comments
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </li>
              )}
            </ul>

            {/* <ul className="flex-col min-w-full flex list-none absolute bottom-0">
              <li className="px-4 rounded-lg text-white mb-2">
                <a
                  href="https://doseco.vj-soft.com"
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center gap-4 text-sm font-light py-3"
                >
                  <Icon name="home" size="2xl" />
                  Website
                </a>
              </li>
            </ul> */}
          </div>
        </div>
      </div>
    </>
  );
}
