import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import PropsType from "prop-types";
import {
  DataGrid,
  Column,
  Pager,
  Paging,
  Editing,
  FilterPanel,
  Button,
  FilterRow,
} from "devextreme-react/data-grid";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../data/dictionary";
import { BASE_URL } from "../utils/constants";
import "devextreme-react/text-area";
import useCustomStore from "../hook/useCustomStore";
import viMessages from "devextreme/localization/messages/vi.json";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import ChangePassPopup from "../components/account/ChangePassPopup";

const allowedPageSizes = [10, 20, "all"];
loadMessages(dictionary);
loadMessages(viMessages);
locale("vi");

const URL = `${BASE_URL}user`;
const usersData = useCustomStore(URL, {
  key: "id",
});

const AccountContainer = (props) => {
  const dataGridRef = useRef(null);

  const [isEdit, setEdit] = useState(false);
  const [changePassPopup, setChangePassPopup] = useState(false);
  const [userId, setUserId] = useState(null);

  usersData.setOptions({
    token: props.token,
  });

  const onEditingStart = () => {
    setEdit(true);
  };

  const onInitNewRow = () => {
    setEdit(false);
  };

  const onChangePass = (e) => {
    setUserId(e?.row?.data?.id);
    setChangePassPopup(true);
  };

  const hideChangePassPopup = () => setChangePassPopup(false);

  return (
    <>
      <div className="bg-light-blue-500 px-3 md:px-8 h-40" />

      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <CardHeader color="purple" contentPosition="none">
                <div className="w-full flex items-center justify-between">
                  <h2 className="text-white text-2xl">Danh sách tài khoản</h2>
                </div>
              </CardHeader>
              <CardBody>
                <DataGrid
                  id="grid"
                  ref={dataGridRef}
                  showBorders={true}
                  dataSource={usersData.store}
                  selection={{ mode: "single" }}
                  repaintChangesOnly={true}
                  allowColumnResizing={true}
                  remoteOperations={true}
                  onEditingStart={onEditingStart}
                  onInitNewRow={onInitNewRow}
                >
                  <FilterRow visible={true} />
                  <FilterPanel visible={true} />

                  <Editing
                    refreshMode={"reshape"}
                    mode="form"
                    useIcons={true}
                    allowAdding={true}
                    allowDeleting={true}
                    allowUpdating={true}
                  />

                  <Paging defaultPageSize={20} />
                  <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    displayMode={"full"}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                  />

                  <Column
                    dataField="username"
                    caption={formatMessage("username")}
                    formItem={{ isRequired: true }}
                  />
                  <Column dataField="email" caption={formatMessage("email")} />
                  <Column
                    dataField="fullName"
                    caption={formatMessage("fullName")}
                    formItem={{ isRequired: true }}
                  />
                  <Column
                    dataField="password"
                    caption={formatMessage("password")}
                    visible={false}
                    formItem={{
                      visible: !isEdit,
                      isRequired: true,
                    }}
                  />
                  <Column
                    dataField="createdAt"
                    caption={formatMessage("createdAt")}
                    dataType="datetime"
                    formItem={{
                      visible: false,
                    }}
                  />

                  <Column type="buttons">
                    <Button name="edit" />
                    <Button
                      hint={"Đổi mật khẩu"}
                      icon="revert"
                      onClick={onChangePass}
                    />
                    <Button name="delete" />
                  </Column>
                </DataGrid>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>

      <ChangePassPopup
        visible={changePassPopup}
        hidePopup={hideChangePassPopup}
        token={props.token}
        userId={userId}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.data.token,
  };
};

AccountContainer.propTypes = {
  token: PropsType.string,
};

export default connect(mapStateToProps, null)(AccountContainer);
