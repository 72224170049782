import React, { useState, useEffect } from "react";
import Toolbar, { Item as ToolbarItem } from "devextreme-react/toolbar";
import Form, {
  ButtonItem,
  SimpleItem,
  RequiredRule,
  Label,
  GroupItem,
} from "devextreme-react/form";
import { formatMessage } from "devextreme/localization";
import { BASE_URL, VERSION } from "../utils/constants";
import { PromotionMV, PromotionVM } from "../model/Promotion";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import PropsType from "prop-types";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import CouponsList from "../components/promotion/CouponsList";
import tranformer from "../utils/transformer";

var qs = require("qs");

function PromotionDetailContainer({ token }) {
  const [promotion, setPromotion] = useState({
    discountType: false,
    allProduct: true,
  });
  const { promotionId } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (!promotionId) {
      return;
    }

    const include = ["coupons"];
    fetch(
      `${BASE_URL}${VERSION}promotion/${promotionId}?${qs.stringify({
        include,
      })}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
    ).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            setPromotion(PromotionVM(json.data));
            return;
          }
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/expiredToken");
          }
        })
    );
  }, []);

  const buttonOptions = {
    text: "Update",
    type: "success",
    useSubmitBehavior: true,
  };

  const backButtonOptions = {
    type: "back",
    onClick: () => {
      history.goBack();
    },
  };

  const handleSubmit = (e) => {
    if (promotion.id) {
      tranformer.fetchUpdate(`${BASE_URL}${VERSION}promotion/${promotion.id}`, {
        token,
        body: PromotionMV(promotion),
        callback: () => {},
      });
    } else {
      tranformer.fetchCreate(`${BASE_URL}${VERSION}promotion`, {
        token,
        body: PromotionMV(promotion),
        callback: () => {
          history.goBack();
        },
      });
    }

    e.preventDefault();
  };

  const datetimeEditorOptions = {
    type: "datetime",
  };

  return (
    <>
      <div className="bg-light-blue-500 px-3 md:px-8 h-40" />

      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <Card>
            <CardHeader color="purple" contentPosition="left">
              <h2 className="text-white text-2xl">Chi tiết khuyến mại</h2>
            </CardHeader>
            <CardBody>
              <Toolbar>
                <ToolbarItem
                  location="before"
                  widget="dxButton"
                  options={backButtonOptions}
                />
                <ToolbarItem
                  location="center"
                  locateInMenu="never"
                  text={"Thông tin Khuyến mại"}
                />
              </Toolbar>
              <div className="grid grid-cols-1 xl:grid-cols-5 px-4 mb-16">
                <div className="xl:col-start-1 xl:col-end-4 px-4 mb-14">
                  <CouponsList promotionId={promotion.id} token={token} />
                </div>

                <div className="xl:col-start-4 xl:col-end-6 px-4 mb-14">
                  <form action="your-action" onSubmit={handleSubmit}>
                    <Form
                      formData={promotion}
                      readOnly={false}
                      showColonAfterLabel={true}
                      colCount={1}
                      showValidationSummary={true}
                      validationGroup="promotionData"
                    >
                      <GroupItem caption="Thông tin">
                        <SimpleItem dataField="name" editorType="dxTextBox">
                          <Label text={formatMessage("name")} />
                          <RequiredRule />
                        </SimpleItem>

                        <SimpleItem
                          dataField="description"
                          editorType="dxTextArea"
                        >
                          <Label text={formatMessage("description")} />
                        </SimpleItem>

                        <SimpleItem dataField="note" editorType="dxTextArea">
                          <Label text={formatMessage("note")} />
                        </SimpleItem>

                        <SimpleItem dataField="active" editorType="dxCheckBox">
                          <Label text={formatMessage("active")} />
                        </SimpleItem>

                        <SimpleItem
                          dataField="startAt"
                          editorType="dxDateBox"
                          editorOptions={datetimeEditorOptions}
                        >
                          <Label text={formatMessage("startAt")} />
                          <RequiredRule />
                        </SimpleItem>

                        <SimpleItem
                          dataField="endAt"
                          editorType="dxDateBox"
                          editorOptions={datetimeEditorOptions}
                        >
                          <Label text={formatMessage("endAt")} />
                          <RequiredRule />
                        </SimpleItem>
                      </GroupItem>

                      <ButtonItem
                        horizontalAlignment="center"
                        colSpan={2}
                        validationGroup="promotionData"
                        buttonOptions={buttonOptions}
                      />
                    </Form>
                  </form>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.data.token,
  };
};

PromotionDetailContainer.propTypes = {
  token: PropsType.string,
};

export default connect(mapStateToProps, null)(PromotionDetailContainer);
