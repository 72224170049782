import tranformer from "../utils/transformer";

const ProductVM = (data) => {
  const returnData = Object.assign({}, data);
  if (!data.thumbnail) {
    returnData.thumbnail = {};
  }
  if (!data.category) {
    returnData.category = { meta: {} };
  }
  if (!data.meta) {
    returnData.meta = [];
  }
  if (data.groups) {
    returnData.groupId = data.groups.map((x) => x.id);
  }

  returnData.categoryName = data.category ? data.category.name : "";
  returnData.brandName = data.brand ? data.brand.name : "";

  return returnData;
};

const ProductMV = (data) => {
  return {
    sku: data.sku,
    name: data.name,
    slug: data.slug,
    type: data.type,
    salePrice: data.salePrice,
    price: data.price,
    category: data.categoryId ? { id: data.categoryId } : undefined,
    brand: data.brandId ? { id: data.brandId } : undefined,
    thumbnail: data.thumbnail ? { id: data.thumbnail.id } : undefined,
    groups:
      tranformer.isNotEmpty(data.groupId) &&
      tranformer.isNotEmptyArr(data.groupId)
        ? data.groupId.map((x) => {
            return { id: x };
          })
        : undefined,
    friendlyGroup: data.friendlyGroupId
      ? { id: data.friendlyGroupId }
      : undefined,
  };
};

export { ProductVM, ProductMV };
