import { createAction } from "redux-api-middleware";
import { BASE_URL } from "../utils/constants";
import { parseBodyUrlEncoded } from "../utils/formBodyParse";

var cookie = require("cookie-cutter");

export const LOGIN_REQUEST = "@@auth/LOGIN_REQUEST";
export const LOGIN_SUCCESS = "@@auth/LOGIN_SUCCESS";
export const LOGIN_FAILURE = "@@auth/LOGIN_FAILURE";

export const login = (data) =>
  createAction({
    endpoint: `${BASE_URL}token`,
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
    },
    types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
    body: parseBodyUrlEncoded(data),
  });

export const AUTH_CHECK_OK = "@@auth/AUTH_CHECK_OK";
export const AUTH_CHECK_FAIL = "@@auth/AUTH_CHECK_FAIL";

export const checkAuth = () => {
  const access_token = cookie.get("token");
  const refresh_token = cookie.get("refresh_token");

  if (access_token) {
    return { type: AUTH_CHECK_OK, payload: { access_token, refresh_token } };
  } else {
    if (refresh_token) {
      return getNewToken({
        grant_type: "refresh_token",
        refresh_token: refresh_token,
      });
    } else {
      return { type: AUTH_CHECK_FAIL };
    }
  }
};

export const AUTH_CLEAR_TOKEN = "@@auth/AUTH_CLEAR_TOKEN";

export const clearToken = () => {
  return { type: AUTH_CLEAR_TOKEN };
};

export const LOGOUT_REQUEST = "@@auth/LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "@@auth/LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "@@auth/LOGOUT_FAILURE";

export const logout = (token) =>
  createAction({
    endpoint: `${BASE_URL}logout`,
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    types: [LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE],
  });

export const REFRESH_REQUEST = "@@auth/REFRESH_REQUEST";
export const REFRESH_SUCCESS = "@@auth/REFRESH_SUCCESS";
export const REFRESH_FAILURE = "@@auth/REFRESH_FAILURE";

export const getNewToken = (data) =>
  createAction({
    endpoint: `${BASE_URL}token`,
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
    },
    types: [REFRESH_REQUEST, REFRESH_SUCCESS, REFRESH_FAILURE],
    body: parseBodyUrlEncoded(data),
  });
