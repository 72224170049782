import React, { useEffect, useState } from "react";
import Form, {
  ButtonItem,
  SimpleItem,
  GroupItem,
  Label,
  RequiredRule,
} from "devextreme-react/form";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { useHistory, useParams } from "react-router-dom";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../data/dictionary";
import { BASE_URL, VERSION } from "../utils/constants";
import { connect } from "react-redux";
import PropsType from "prop-types";
import ImageUploader from "../components/ImageUploader";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import useFetch from "../hook/useFetch";
import tranformer from "../utils/transformer";
import { FeedbackMV, FeedbackVM } from "../model/Feedback";

loadMessages(dictionary);
locale("vi");
var qs = require("qs");

const FeedbackDetailContainer = ({ token }) => {
  const history = useHistory();
  const { feedbackId } = useParams();
  const [post, setPost] = useState({});

  const groups = useFetch(`${BASE_URL}${VERSION}group`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    },
  });

  useEffect(() => {
    if (!feedbackId) {
      return;
    }
    const include = ["thumbnail", "group"];
    fetch(
      `${BASE_URL}${VERSION}post/${feedbackId}?${qs.stringify({ include })}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
    ).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            setPost(FeedbackVM(json.data));
            return;
          }
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/expiredToken");
          }
        })
    );
  }, [token, feedbackId, history]);

  const backButtonOptions = {
    type: "back",
    onClick: () => {
      history.goBack();
    },
  };

  const saveButtonOptions = {
    text: formatMessage("save"),
    icon: "save",
    type: "default",
    stylingMode: "contained",
    useSubmitBehavior: true,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (feedbackId) {
      tranformer.fetchUpdate(`${BASE_URL}${VERSION}post/${post.id}`, {
        token,
        body: FeedbackMV(post),
        callback: () => {},
      });
    } else {
      tranformer.fetchCreate(`${BASE_URL}${VERSION}post`, {
        token,
        body: FeedbackMV(post),
        callback: () => {},
      });
    }
  };

  const groupIdSelectOptions = {
    items: groups.data.filter((x) => x.parentId === 7),
    displayExpr: "name",
    valueExpr: "id",
  };

  return (
    <>
      <div className="bg-light-blue-500 px-3 md:px-8 h-40" />

      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <CardHeader color="purple" contentPosition="left">
                <h2 className="text-white text-2xl">
                  {feedbackId ? "Chi tiết Feedback" : "Thêm mới Feedback"}
                </h2>
              </CardHeader>
              <CardBody>
                <Toolbar>
                  <Item
                    location="before"
                    widget="dxButton"
                    options={backButtonOptions}
                  />
                  <Item
                    location="center"
                    text={`${formatMessage("info")} ${formatMessage(
                      "of"
                    )} ${formatMessage("feedback")}`}
                  />
                </Toolbar>

                <form action="your-action" onSubmit={handleSubmit}>
                  <Form
                    formData={post}
                    readOnly={false}
                    showColonAfterLabel={true}
                    colCount={1}
                    showValidationSummary={true}
                  >
                    <GroupItem caption="Thông tin" colCount={2}>
                      <GroupItem>
                        <SimpleItem dataField={"title"} editorType="dxTextBox">
                          <Label text={formatMessage("title")} />
                          <RequiredRule />
                        </SimpleItem>

                        <SimpleItem
                          dataField={"content"}
                          editorType="dxTextArea"
                        >
                          <Label text={formatMessage("content")} />
                          <RequiredRule />
                        </SimpleItem>

                        <SimpleItem dataField={"link"} editorType="dxTextBox">
                          <Label text={formatMessage("link")} />
                          <RequiredRule />
                        </SimpleItem>

                        <SimpleItem
                          dataField={"groupId"}
                          editorType="dxSelectBox"
                          editorOptions={groupIdSelectOptions}
                        >
                          <Label text={formatMessage("group")} />
                        </SimpleItem>
                      </GroupItem>

                      <GroupItem>
                        <SimpleItem>
                          <Label text={formatMessage("thumbnail")} />
                          <ImageUploader
                            defaultValue={post.thumbnail}
                            onImgUrlChange={(value) =>
                              setPost({ ...post, thumbnail: value })
                            }
                            token={token}
                          />
                        </SimpleItem>
                      </GroupItem>
                    </GroupItem>

                    <ButtonItem
                      horizontalAlignment="center"
                      colSpan={3}
                      buttonOptions={saveButtonOptions}
                    />
                  </Form>
                </form>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.data.token,
    lang: state.setting.language.currentLang,
  };
};

FeedbackDetailContainer.propTypes = {
  token: PropsType.string,
};

export default connect(mapStateToProps, null)(FeedbackDetailContainer);
