import React, { useState } from "react";
import Form, {
  ButtonItem,
  SimpleItem,
  RequiredRule,
  Label,
  GroupItem,
} from "devextreme-react/form";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../../data/dictionary";
import { BASE_URL } from "../../utils/constants";
import { CommentMV } from "../../model/Comment";
import PropsType from "prop-types";
import tranformer from "../../utils/transformer";
import { Popup } from "devextreme-react/popup";

loadMessages(dictionary);
locale("vi");

function ReplyFormPopup({ token, hidePopup, onRefresh, visible, currentItem }) {
  const [comment] = useState({
    customerName: "Doseco Admin",
    content: "",
  });

  const buttonOptions = {
    text: "Update",
    type: "success",
    useSubmitBehavior: true,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    tranformer.fetchCreate(`${BASE_URL}comment`, {
      token,
      body: CommentMV({
        ...comment,
        parent: { id: currentItem.id },
        post: { id: currentItem.post.id },
      }),
      callback: () => {
        onRefresh();
        hidePopup();
      },
    });
  };

  return (
    <Popup
      visible={visible}
      onHiding={hidePopup}
      dragEnabled={false}
      closeOnOutsideClick={false}
      showCloseButton={true}
      showTitle={true}
      title="Trả lời"
      container=".widget-container"
      width={600}
      height={250}
    >
      <form action="your-action" onSubmit={handleSubmit}>
        <Form
          formData={comment}
          readOnly={false}
          showColonAfterLabel={true}
          colCount={1}
          showValidationSummary={true}
          validationGroup="customerData"
        >
          <GroupItem>
            <GroupItem>
              <SimpleItem dataField="customerName" editorType="dxTextBox">
                <Label text={formatMessage("fullName")} />
                <RequiredRule />
              </SimpleItem>

              <SimpleItem dataField="content" editorType="dxTextArea">
                <Label text={formatMessage("content")} />
                <RequiredRule />
              </SimpleItem>
            </GroupItem>
          </GroupItem>

          <ButtonItem
            horizontalAlignment="center"
            buttonOptions={buttonOptions}
          />
        </Form>
      </form>
    </Popup>
  );
}

ReplyFormPopup.propTypes = {
  token: PropsType.string,
  couponId: PropsType.number,
  hidePopup: PropsType.func,
  onRefresh: PropsType.func,
  visible: PropsType.bool,
  currentItem: PropsType.object,
};

export default ReplyFormPopup;
