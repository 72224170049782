import React, { useEffect, useState } from "react";
import Form, {
  ButtonItem,
  SimpleItem,
  GroupItem,
  Label,
  RequiredRule,
} from "devextreme-react/form";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { useHistory, useParams } from "react-router-dom";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../data/dictionary";
import {
  BASE_URL,
  GROUP_TYPE,
  PRODUCT_TYPE,
  VERSION,
  FRIENDLY_GROUP_TYPE,
  HTML_ITEMS,
} from "../utils/constants";
import { connect } from "react-redux";
import PropsType from "prop-types";
import notify from "devextreme/ui/notify";
import { GroupMV, GroupVM } from "../model/Group";
import ImageUploader from "../components/ImageUploader";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import useFetch from "../hook/useFetch";
import GroupProducts from "../components/group/GroupProducts";
import tranformer from "../utils/transformer";

loadMessages(dictionary);
locale("vi");
var qs = require("qs");

const GroupDetailContainer = ({ token }) => {
  const history = useHistory();
  const params = useParams();
  const [group, setGroup] = useState({});

  const groups = useFetch(`${BASE_URL}${VERSION}group`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    },
  });

  const refreshGroup = () => {
    if (!params.id) {
      return;
    }
    const include = [
      "parent",
      "thumbnail",
      "products",
      "friendlyProduct",
      "children",
    ];
    fetch(
      `${BASE_URL}${VERSION}group/${params.id}?${qs.stringify({ include })}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
    ).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            setGroup(GroupVM(json.data));
            return;
          }
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/expiredToken");
          }
        })
    );
  };

  useEffect(() => {
    refreshGroup();
  }, [token, params.id, history]);

  const backButtonOptions = {
    type: "back",
    onClick: () => {
      history.goBack();
    },
  };

  const saveButtonOptions = {
    text: formatMessage("save"),
    icon: "save",
    type: "default",
    stylingMode: "contained",
    useSubmitBehavior: true,
  };

  const updateGroup = () => {
    return fetch(`${BASE_URL}${VERSION}group/${group.id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(GroupMV(group)),
    }).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            history.goBack();
            notify(
              {
                message: json.message,
                position: {
                  my: "center top",
                  at: "center top",
                },
              },
              "success",
              3000
            );
            return;
          }
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/expiredToken");
          }
        })
    );
  };

  const addGroup = () => {
    return fetch(`${BASE_URL}${VERSION}group`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(GroupMV(group)),
    }).then((result) =>
      result
        .json()
        .then((json) => {
          if (result.ok) {
            history.goBack();
            notify(
              {
                message: json.message,
                position: {
                  my: "center top",
                  at: "center top",
                },
              },
              "success",
              3000
            );
            return;
          }
          throw json.Message;
        })
        .catch(() => {
          if (result.status === 401) {
            history.push("/expiredToken");
          }
        })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (params.id) {
      updateGroup();
    } else {
      addGroup();
    }
  };

  const typesSelectOptions = {
    items: GROUP_TYPE,
    displayExpr: "name",
    valueExpr: "value",
    onValueChanged: (args) => {
      setGroup((prevState) => ({
        ...prevState,
        type: args.value,
      }));
    },
  };

  const parentIdSelectOptions = {
    items: groups.data,
    displayExpr: "name",
    valueExpr: "id",
    showClearButton: true,
    searchEnabled: true,
  };

  const nameEditorOptions = {
    onValueChanged: (args) => {
      setGroup((prevState) => ({
        ...prevState,
        key: tranformer.convertUnsignedChars(args.value),
      }));
    },
  };

  const referenceGroupEditOptions = {
    onValueChanged: (args) => {
      setGroup((prevState) => ({
        ...prevState,
        referenceGroup: args.value,
      }));
    },
  };

  const contentHtmlOptions = {
    toolbar: {
      items: HTML_ITEMS,
    },
    height: 400,
  };

  return (
    <>
      <div className="bg-light-blue-500 px-3 md:px-8 h-40" />

      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <CardHeader color="purple" contentPosition="left">
                <h2 className="text-white text-2xl">
                  {params.id ? "Chi tiết nhóm" : "Thêm mới nhóm"}
                </h2>
              </CardHeader>
              <CardBody>
                <Toolbar>
                  <Item
                    location="before"
                    widget="dxButton"
                    options={backButtonOptions}
                  />
                  <Item
                    location="center"
                    text={`${formatMessage("info")} ${formatMessage(
                      "of"
                    )} ${formatMessage("group")}`}
                  />
                </Toolbar>

                <form action="your-action" onSubmit={handleSubmit}>
                  <Form
                    formData={group}
                    readOnly={false}
                    showColonAfterLabel={true}
                    colCount={1}
                    showValidationSummary={true}
                  >
                    <GroupItem caption="Thông tin" colCount={2}>
                      <GroupItem>
                        <SimpleItem>
                          <Label text={formatMessage("thumbnail")} />
                          <ImageUploader
                            defaultValue={group.thumbnail}
                            onImgUrlChange={(value) =>
                              setGroup({ ...group, thumbnail: value })
                            }
                            token={token}
                          />
                        </SimpleItem>

                        <SimpleItem
                          dataField={"name"}
                          editorType="dxTextBox"
                          editorOptions={nameEditorOptions}
                        >
                          <Label text={formatMessage("name")} />
                          <RequiredRule />
                        </SimpleItem>
                        <SimpleItem
                          dataField={"key"}
                          editorOptions={{ readOnly: !!params.id }}
                        >
                          <Label text={formatMessage("key")} />
                          <RequiredRule />
                        </SimpleItem>
                        <SimpleItem
                          dataField={"type"}
                          editorType="dxSelectBox"
                          editorOptions={typesSelectOptions}
                        >
                          <Label text={formatMessage("type")} />
                          <RequiredRule />
                        </SimpleItem>
                        <SimpleItem
                          dataField={"homepage"}
                          editorType="dxCheckBox"
                        >
                          <Label text={formatMessage("isHomePage")} />
                        </SimpleItem>
                        <SimpleItem
                          dataField={"parentId"}
                          editorType="dxSelectBox"
                          editorOptions={parentIdSelectOptions}
                        >
                          <Label text={formatMessage("parent")} />
                        </SimpleItem>
                      </GroupItem>

                      <GroupItem>
                        <SimpleItem
                          dataField={"description"}
                          editorType="dxHtmlEditor"
                          editorOptions={contentHtmlOptions}
                        >
                          <Label text={formatMessage("description")} />
                          <RequiredRule />
                        </SimpleItem>
                      </GroupItem>

                      <GroupItem
                        visible={PRODUCT_TYPE.map((x) => x.id).includes(
                          group.type
                        )}
                      >
                        <SimpleItem
                          dataField={"referenceGroup"}
                          editorType="dxSwitch"
                          editorOptions={referenceGroupEditOptions}
                        >
                          <Label text={formatMessage("referenceGroup")} />
                        </SimpleItem>

                        <SimpleItem
                          dataField={"reference"}
                          editorType="dxTextBox"
                          visible={!!group.referenceGroup}
                        >
                          <Label text={formatMessage("reference")} />
                        </SimpleItem>
                      </GroupItem>
                    </GroupItem>

                    <ButtonItem
                      horizontalAlignment="center"
                      colSpan={3}
                      buttonOptions={saveButtonOptions}
                    />
                  </Form>
                </form>

                {!!params.id &&
                  !group.referenceGroup &&
                  (PRODUCT_TYPE.map((x) => x.id).includes(group.type) ||
                    group.type === FRIENDLY_GROUP_TYPE) && (
                    <GroupProducts
                      data={
                        group.type !== FRIENDLY_GROUP_TYPE
                          ? group.products
                          : group.friendlyProduct
                      }
                      type={group.type}
                      token={token}
                      refreshList={refreshGroup}
                      groupId={group.id}
                      groupKey={group.key}
                      childGroupId={group.children[0]?.id ?? null}
                    />
                  )}
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.data.token,
    lang: state.setting.language.currentLang,
  };
};

GroupDetailContainer.propTypes = {
  token: PropsType.string,
};

export default connect(mapStateToProps, null)(GroupDetailContainer);
