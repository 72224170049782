const GroupVM = (data) => {
  const returnData = Object.assign({}, data);

  return returnData;
};

const GroupMV = (data) => {
  return {
    name: data.name,
    type: data.type,
    key: data.key,
    homepage: data.homepage,
    thumbnail: data.thumbnail ? { id: data.thumbnail.id } : undefined,
    parent: data.parentId ? { id: data.parentId } : undefined,
    reference: data.reference,
    referenceGroup: data.referenceGroup,
  };
};

export { GroupVM, GroupMV };
