import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import PropsType from "prop-types";
import {
  DataGrid,
  Column,
  Editing,
  Scrolling,
  RequiredRule,
  Pager,
  Paging,
  FormItem,
  Button,
} from "devextreme-react/data-grid";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../data/dictionary";
import { BASE_URL, HOST } from "../utils/constants";
import "devextreme-react/text-area";
import useCustomStore from "../hook/useCustomStore";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import ReplyFormPopup from "../components/comment/ReplyFormPopup";

const allowedPageSizes = [10, 20, "all"];
loadMessages(dictionary);
locale("vi");
const notesEditorOptions = { height: 100 };

const URL = `${BASE_URL}comment`;
const commentsData = useCustomStore(URL, {
  key: "id",
  defaultParams: { parentId: "" },
});

const CommentContainer = (props) => {
  const dataGridRef = useRef(null);
  const [showReply, setShowReply] = useState(false);
  const [selectedComment, setSelectedComment] = useState({});

  commentsData.setOptions({
    token: props.token,
    filter: {},
    include: [],
  });

  const onToolbarPreparing = (e) => {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift({
      location: "after",
      widget: "dxButton",
      locateInMenu: "auto",
      options: {
        icon: "refresh",
        onClick: () => dataGridRef.current.instance.refresh(),
      },
    });
  };

  const urlCellRender = (data) => {
    const { brand, slug } = data.data?.post || {};
    const url = `${HOST}b/${brand?.name}/${slug}`;
    return (
      <a target={"_blank"} href={url} rel="noreferrer">
        {data.value}
      </a>
    );
  };

  const replyCellRender = (data) => {
    if (!data.value || data.value.length === 0) {
      return "";
    }
    const { content, customerName } = data.value[0] || {};
    return <span>{`${customerName} | ${content}`}</span>;
  };

  const hideReplyPopup = () => setShowReply(false);

  const showReplyPopup = (e) => {
    setSelectedComment(e.row.data);
    setShowReply(true);
  };

  const onRefresh = () => {
    dataGridRef.current.instance.refresh();
  };

  return (
    <>
      <div className="bg-light-blue-500 px-3 md:px-8 h-40" />

      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <CardHeader color="purple" contentPosition="none">
                <div className="w-full flex items-center justify-between">
                  <h2 className="text-white text-2xl">Danh sách comments</h2>
                </div>
              </CardHeader>
              <CardBody>
                <DataGrid
                  id="grid"
                  showBorders={true}
                  ref={dataGridRef}
                  dataSource={commentsData.store}
                  selection={{ mode: "single" }}
                  repaintChangesOnly={true}
                  allowColumnResizing={true}
                  onToolbarPreparing={onToolbarPreparing}
                >
                  <Editing
                    refreshMode={"reshape"}
                    mode="cell"
                    useIcons={true}
                    // allowAdding={true}
                    allowDeleting={true}
                    // allowUpdating={true}
                  />

                  <Scrolling rowRenderingMode="virtual" />

                  <Paging defaultPageSize={20} />
                  <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    displayMode={"full"}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                  />

                  <Column
                    dataField="createdAt"
                    caption={formatMessage("createdAt")}
                    dataType="datetime"
                  >
                    <RequiredRule />
                  </Column>
                  <Column
                    dataField="customerName"
                    caption={formatMessage("fullName")}
                  />
                  <Column
                    dataField="post.name"
                    caption={formatMessage("name")}
                    cellRender={urlCellRender}
                  />
                  <Column
                    dataField="content"
                    caption={formatMessage("content")}
                  >
                    <FormItem
                      colSpan={2}
                      editorType="dxTextArea"
                      editorOptions={notesEditorOptions}
                    />
                  </Column>
                  <Column
                    dataField="children"
                    caption={formatMessage("reply")}
                    cellRender={replyCellRender}
                  />
                  <Column type="buttons" width={110}>
                    <Button name="delete" />
                    <Button
                      hint="Reply"
                      icon="revert"
                      onClick={showReplyPopup}
                    />
                  </Column>
                </DataGrid>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>

      <ReplyFormPopup
        visible={showReply}
        hidePopup={hideReplyPopup}
        currentItem={selectedComment}
        onRefresh={onRefresh}
        token={props.token}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.data.token,
  };
};

CommentContainer.propTypes = {
  token: PropsType.string,
};

export default connect(mapStateToProps, null)(CommentContainer);
