import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { BASE_URL, VERSION } from "../../utils/constants";
import { Button } from "devextreme-react/button";
import Box, { Item } from "devextreme-react/box";
import DropDownBox from "devextreme-react/drop-down-box";
import DataGrid, {
  Selection,
  Paging,
  FilterRow,
  Column,
  Pager,
  Lookup,
} from "devextreme-react/data-grid";
import useCustomStore from "../../hook/useCustomStore";
import ScrollView from "devextreme-react/scroll-view";
import { formatMessage } from "devextreme/localization";
import "./product-selectbox.css";
import useFetch from "../../hook/useFetch";

const URL = `${BASE_URL}${VERSION}product`;
const productsData = useCustomStore(URL, {
  key: "id",
  filter: {},
  include: ["category"],
});

const ProductSelectBox = ({ onValueChanged, filter, token }) => {
  const dataGridRef = useRef(null);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedProductNames, setSelectedProductNames] = useState(
    "Chưa chọn sản phẩm nào"
  );
  productsData.setOptions({
    token: token,
    filter: filter,
  });

  const categories = useFetch(`${BASE_URL}${VERSION}category`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    },
  });

  function getEmployeeName(row) {
    return `${row.name}`;
  }

  function getEmployeeNames(selectedRowsData) {
    return selectedRowsData.length
      ? selectedRowsData.map(getEmployeeName).join(", ")
      : "Chưa chọn sản phẩm nào";
  }

  const handleValueChange = (e) => {
    setSelectedProductIds(
      (e.selectedRowKeys.length && e.selectedRowKeys) || []
    );
    setSelectedProductNames(getEmployeeNames(e.selectedRowsData));
    setSelectedProduct(e.selectedRowsData);
  };

  const saveClick = () => {
    onValueChanged(selectedProduct);
  };

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "clear",
        text: "Xóa đã chọn",
        onClick: () => dataGridRef.current.instance.clearSelection(),
      },
      disabled: !selectedProductIds.length,
    });
  };

  const dataGridRender = () => {
    return (
      <ScrollView width="100%" height="100%">
        <DataGrid
          ref={dataGridRef}
          dataSource={productsData.store}
          remoteOperations={true}
          hoverStateEnabled={true}
          selectedRowKeys={selectedProductIds}
          onSelectionChanged={handleValueChange}
          onToolbarPreparing={onToolbarPreparing}
        >
          <Selection mode="multiple" selectAllMode="page" />
          <Paging enabled={true} defaultPageSize={5} />
          <Pager
            visible={true}
            allowedPageSizes={[5, 10, 20, "all"]}
            displayMode={"full"}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
          />
          <FilterRow visible={true} />

          <Column dataField="sku" caption={formatMessage("sku")} />
          <Column dataField="name" caption={formatMessage("name")} />
          <Column dataField="category.name" caption={formatMessage("category")}>
            <Lookup
              dataSource={categories.data}
              valueExpr="name"
              displayExpr="name"
            />
          </Column>
          <Column
            dataField="price"
            dataType="number"
            format={{ style: "currency", currency: "VND" }}
            caption={formatMessage("price")}
          />
        </DataGrid>

        <div className="selected-data">
          <span className="caption">Selected Records:</span>{" "}
          <span>{selectedProductNames}</span>
        </div>
      </ScrollView>
    );
  };

  const syncDataGridSelection = (e) => {
    setSelectedProductIds(e.value || []);
  };

  return (
    <div style={{ marginTop: "15px" }}>
      <Box direction="row" crossAlign="stretch" width="100%">
        <Item ratio={1}>
          <DropDownBox
            value={selectedProductIds}
            valueExpr="id"
            deferRendering={false}
            displayExpr="name"
            placeholder="Thêm sản phẩm vào nhóm"
            dataSource={productsData.store}
            onValueChanged={syncDataGridSelection}
            contentRender={dataGridRender}
          />
        </Item>

        <Item ratio={0} baseSize={20}></Item>

        <Item ratio={0} baseSize={50}>
          <Button
            icon="add"
            disabled={!selectedProductIds}
            onClick={saveClick}
          />
        </Item>
      </Box>
    </div>
  );
};

ProductSelectBox.propTypes = {
  token: PropTypes.string,
  onValueChanged: PropTypes.func,
  filter: PropTypes.object,
};

export default ProductSelectBox;
