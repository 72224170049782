import tranformer from "../utils/transformer";

const CouponVM = (data) => {
  const returnData = Object.assign({}, data);
  returnData.categories = data.categories
    ? data.categories.map((x) => x.id)
    : null;
  returnData.groups = data.groups ? data.groups.map((x) => x.id) : null;

  return returnData;
};

const CouponMV = (data) => {
  return {
    code: data.code,
    type: data.type,
    description: data.description,
    fromTotal: data.fromTotal,
    discount: data.discount,
    productType: data.productType,
    paymentMethod: data.paymentMethodId
      ? { id: data.paymentMethodId }
      : undefined,
    products:
      tranformer.isNotEmpty(data.products) &&
      tranformer.isNotEmptyArr(data.products)
        ? data.products.map((x) => {
            return { id: x.id };
          })
        : undefined,
    categories:
      tranformer.isNotEmpty(data.categories) &&
      tranformer.isNotEmptyArr(data.categories)
        ? data.categories.map((x) => {
            return { id: x };
          })
        : undefined,
    groups:
      tranformer.isNotEmpty(data.groups) &&
      tranformer.isNotEmptyArr(data.categories)
        ? data.groups.map((x) => {
            return { id: x };
          })
        : undefined,
    isPercent: data.isPercent,
    maxDiscount: data.maxDiscount,
    useLimit: data.useLimit,
    limitOnCustomer: data.limitOnCustomer,
    limitOnProduct: data.limitOnProduct,
    customerType: data.customerType,
  };
};

export { CouponVM, CouponMV };
