import React, { useEffect, useState } from "react";
import { loadMessages, locale } from "devextreme/localization";
import dictionary from "../data/dictionary";
import PropsType from "prop-types";
import SelectBox from "devextreme-react/select-box";
import { BASE_URL, FILE_URL, VERSION } from "../utils/constants";
import { NumberBox } from "devextreme-react/number-box";
import { TextBox } from "devextreme-react/text-box";
import RadioGroup from "devextreme-react/radio-group";
import Box, { Item } from "devextreme-react/box";
import Card from "@material-tailwind/react/Card";
import CardBody from "@material-tailwind/react/CardBody";
import tranformer from "../utils/transformer";

loadMessages(dictionary);
locale("vi");

function mergeMetaCategory(categoryMeta, meta) {
  const returnData = [];
  Object.keys(categoryMeta).forEach((element) => {
    if (categoryMeta[element].filter) {
      return;
    }
    const selectedMeta = meta.find((x) => x.key === element);

    returnData.push({
      id: selectedMeta ? selectedMeta.id : null,
      key: element,
      title: categoryMeta[element].title,
      type: categoryMeta[element].type,
      unit: categoryMeta[element].unit,
      custom: categoryMeta[element].custom,
      value: selectedMeta ? selectedMeta.value : null,
      items: categoryMeta[element].value,
      filterKey: categoryMeta[element].filterKey,
    });
  });

  return returnData;
}

function ProductInfoList({ product, token }) {
  const [meta, setMeta] = useState(product.meta);

  useEffect(() => {
    setMeta(product.meta);
  }, [product]);

  const insertMeta = (data) => {
    tranformer.fetchCreate(
      `${BASE_URL}${VERSION}product/${product.id}/meta/create`,
      {
        token: token,
        body: data,
        callback: () => {},
      }
    );
  };

  const updateMeta = (data) => {
    tranformer.fetchUpdate(`${BASE_URL}${VERSION}productMeta/${data.id}`, {
      token: token,
      body: { value: data.value },
      callback: () => {},
    });
  };

  const updateMetaProduct = (key, value) => {
    console.log(key, value);
    const newMeta = [];
    const newData = {
      key: key,
      value: value,
    };

    if (meta.findIndex((x) => x.key === key) === -1) {
      newMeta.push(newData);
      insertMeta(newData);
    }

    meta.forEach((element) => {
      if (element.key === key) {
        newMeta.push({
          ...element,
          value: value,
        });
        updateMeta({ ...element, ...newData });
      } else {
        newMeta.push(element);
      }
    });

    setMeta(newMeta);
  };

  const updateMultiMetaProduct = (keyValueArr) => {
    const newMeta = [];

    keyValueArr.forEach((element) => {
      const { key, value } = element;

      const newData = {
        key: key,
        value: value,
      };

      if (meta.findIndex((x) => x.key === key) === -1) {
        newMeta.push(newData);
        insertMeta(newData);
      }
    });

    meta.forEach((element) => {
      if (keyValueArr.findIndex((x) => x.key === element.key) !== -1) {
        const _keyValue = keyValueArr.find((x) => x.key === element.key);
        newMeta.push({
          ...element,
          value: _keyValue.value,
        });
        updateMeta({ ...element, ..._keyValue });
      } else {
        newMeta.push(element);
      }
    });

    setMeta(newMeta);
  };

  const renderCustomItem = (data) => {
    return <img style={{ height: 50 }} src={`${FILE_URL}${data}`} />;
  };

  const customItemCreating = (args) => {
    if (!args.text) {
      args.customItem = null;
      return;
    }

    const newItem = args.text;
    args.customItem = newItem;
  };

  const renderKeyValue = (item, index) => {
    const _items = item.items ? [...item.items] : [];
    if (item.value && !_items.includes(item.value)) {
      _items.push(item.value);
    }

    return (
      <div key={index} className="dx-field">
        <div className="dx-field-label">
          {`${index + 1}. ${item.title} ${
            item.type !== "array" ? `${item.unit ? `(${item.unit})` : ""}` : ""
          }`}{" "}
        </div>
        <div className="dx-field-value">
          {item.type === "array" ? (
            item.title === "Sticker" ? (
              <RadioGroup
                items={item.items}
                itemRender={renderCustomItem}
                layout="horizontal"
                onValueChanged={(agrs) =>
                  updateMetaProduct(item.key, agrs.value)
                }
                defaultValue={item.value}
              />
            ) : (
              <SelectBox
                value={item.value}
                acceptCustomValue={!!item.custom}
                onValueChanged={(agrs) => {
                  if (item.filterKey) {
                    const _value = product.category.meta[item.filterKey].value;
                    if (_value.includes(agrs.value)) {
                      updateMultiMetaProduct([
                        { key: item.key, value: agrs.value },
                        { key: item.filterKey, value: agrs.value },
                      ]);
                    } else {
                      let otherValue = _value.at(-1);
                      updateMultiMetaProduct([
                        { key: item.key, value: agrs.value },
                        { key: item.filterKey, value: otherValue },
                      ]);
                    }
                  } else {
                    updateMetaProduct(item.key, agrs.value);
                  }
                }}
                onCustomItemCreating={customItemCreating}
                items={_items}
              />
            )
          ) : item.type === "number" ? (
            <NumberBox
              value={item.value}
              onValueChanged={(agrs) => {
                if (item.filterKey) {
                  const _value = product.category.meta[item.filterKey].value;

                  let otherValue = tranformer.checkNumberInRange(
                    agrs.value,
                    _value
                  );

                  if (otherValue === null) {
                    updateMetaProduct(item.key, agrs.value);
                  } else {
                    updateMultiMetaProduct([
                      { key: item.key, value: agrs.value },
                      { key: item.filterKey, value: otherValue },
                    ]);
                  }
                } else {
                  updateMetaProduct(item.key, agrs.value);
                }
              }}
            />
          ) : (
            <TextBox
              value={item.value}
              onValueChanged={(agrs) => updateMetaProduct(item.key, agrs.value)}
            />
          )}

          {!!item.custom && (
            <div className="dx-field-item-help-text">
              Nhập và Enter để lưu giá trị mới
            </div>
          )}
        </div>
      </div>
    );
  };

  const metasList = (
    mergeMetaCategory(product.category.meta, meta) || []
  ).filter((item) => item.title !== "Thời gian bảo hành");

  return (
    <>
      <div className="bg-light-blue-500 px-3 md:px-8 h-40" />

      <div className="px-3 md:px-8 h-auto -mt-24 mb-12">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <CardBody>
                <Box direction="row">
                  <Item ratio={1}>
                    {metasList.map((item, index) => {
                      return index % 2 === 0 && renderKeyValue(item, index);
                    })}
                  </Item>
                  <Item ratio={0} baseSize={20} />
                  <Item ratio={1}>
                    {metasList.map((item, index) => {
                      return index % 2 !== 0 && renderKeyValue(item, index);
                    })}
                  </Item>
                </Box>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

ProductInfoList.propTypes = {
  token: PropsType.string,
  product: PropsType.object,
};

export default ProductInfoList;
