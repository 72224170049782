import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import cookie from 'cookie-cutter'

const PrivateRoute = ({
  isAuthenticated,
  hasPermision,
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          hasPermision ? (
            <Component />
          ) : (
            <Redirect
              to={{
                pathname: "/profile",
                state: { from: location },
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = (state) => {
  const token = cookie.get('token');
  return {
    isAuthenticated: state.auth.data.isAuthenticated && !!token,
  };
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
  isAuthenticated: PropTypes.bool.isRequired,
  hasPermision: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(PrivateRoute);
