import React, { useRef } from "react";
import { connect } from "react-redux";
import PropsType from "prop-types";
import {
  DataGrid,
  Column,
  Editing,
  Scrolling,
  RequiredRule,
  Pager,
  Paging,
  Form,
} from "devextreme-react/data-grid";
import { Item } from "devextreme-react/form";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../data/dictionary";
import { BASE_URL, FILE_URL, VERSION } from "../utils/constants";
import "devextreme-react/text-area";
import useCustomStore from "../hook/useCustomStore";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import TextArea from "devextreme-react/text-area";
import TextBox from "devextreme-react/text-box";
import ImageUploader from "../components/ImageUploader";
import tranformer from "../utils/transformer";

const allowedPageSizes = [10, 20, "all"];
loadMessages(dictionary);
locale("vi");

const URL = `${BASE_URL}${VERSION}seo`;
const seosData = useCustomStore(URL, {
  key: "id",
});

const SeoContainer = (props) => {
  const dataGridRef = useRef(null);

  seosData.setOptions({
    token: props.token,
    filter: {},
    include: [],
  });

  const onToolbarPreparing = (e) => {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift({
      location: "after",
      widget: "dxButton",
      locateInMenu: "auto",
      options: {
        icon: "refresh",
        onClick: () => dataGridRef.current.instance.refresh(),
      },
    });
  };

  const urlEditCellRender = (cell) => {
    return (
      <div className="dx-field-value">
        <TextBox
          defaultValue={cell.value}
          placeholder={"Nhập Url"}
          onValueChanged={(e) => {
            const newValue = tranformer.getPathnameFromUrl(e.value);
            cell.setValue(newValue);
          }}
        />
      </div>
    );
  };

  const seoEditCellRender = (cell) => {
    const { title, keyword, description, image } = cell.value || {};
    return (
      <div className="dx-fieldset">
        <div className="dx-field">
          <div className="dx-field-label">{formatMessage("title")}</div>
          <div className="dx-field-value">
            <TextBox
              defaultValue={title}
              placeholder={"Nhập tiêu đề"}
              onValueChanged={(e) => {
                cell.setValue({ ...cell.value, title: e.value });
              }}
            />
          </div>
        </div>

        <div className="dx-field">
          <div className="dx-field-label">{formatMessage("keyword")}</div>
          <div className="dx-field-value">
            <TextBox
              defaultValue={keyword}
              placeholder={"Nhập keyword"}
              onValueChanged={(e) => {
                cell.setValue({ ...cell.value, keyword: e.value });
              }}
            />
          </div>
        </div>

        <div className="dx-field">
          <div className="dx-field-label">{formatMessage("description")}</div>
          <div className="dx-field-value">
            <TextArea
              defaultValue={description}
              placeholder={"Nhập mô tả"}
              onValueChanged={(e) => {
                cell.setValue({ ...cell.value, description: e.value });
              }}
            />
          </div>
        </div>

        <div className="dx-field">
          <div className="dx-field-label">Image</div>
          <div className="dx-field-value">
            <ImageUploader
              defaultValue={image || {}}
              onImgUrlChange={(value) =>
                cell.setValue({ ...cell.value, image: value })
              }
              token={props.token}
            />
          </div>
        </div>
      </div>
    );
  };

  const imgCellRender = (data) => {
    return (
      <img
        style={{ height: 30 }}
        src={`${FILE_URL}${data.value?.path || ""}`}
      />
    );
  };

  return (
    <>
      <div className="bg-light-blue-500 px-3 md:px-8 h-40" />

      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <CardHeader color="purple" contentPosition="none">
                <div className="w-full flex items-center justify-between">
                  <h2 className="text-white text-2xl">Thiết lập SEO</h2>
                </div>
              </CardHeader>
              <CardBody>
                <DataGrid
                  id="grid"
                  showBorders={true}
                  ref={dataGridRef}
                  dataSource={seosData.store}
                  selection={{ mode: "single" }}
                  repaintChangesOnly={true}
                  allowColumnResizing={true}
                  onToolbarPreparing={onToolbarPreparing}
                >
                  <Editing
                    refreshMode={"reshape"}
                    mode="form"
                    useIcons={true}
                    allowAdding={true}
                    allowDeleting={true}
                    allowUpdating={true}
                  >
                    <Form colCount={1}>
                      <Item dataField="url" />
                      <Item dataField="seo" />
                    </Form>
                  </Editing>

                  <Scrolling rowRenderingMode="virtual" />

                  <Paging defaultPageSize={20} />
                  <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    displayMode={"full"}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                  />

                  <Column
                    dataField="url"
                    caption={formatMessage("url")}
                    editCellRender={urlEditCellRender}
                  >
                    <RequiredRule />
                  </Column>
                  <Column
                    visible={false}
                    dataField="seo"
                    caption={formatMessage("seo")}
                    editCellRender={seoEditCellRender}
                  />
                  <Column
                    dataField="seo.title"
                    caption={formatMessage("title")}
                  />
                  <Column
                    dataField="seo.keyword"
                    caption={formatMessage("keyword")}
                  />
                  <Column
                    dataField="seo.description"
                    caption={formatMessage("description")}
                  />
                  <Column
                    dataField="seo.image"
                    caption={formatMessage("image")}
                    allowSorting={false}
                    allowFiltering={false}
                    cellRender={imgCellRender}
                  ></Column>
                </DataGrid>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.data.token,
  };
};

SeoContainer.propTypes = {
  token: PropsType.string,
};

export default connect(mapStateToProps, null)(SeoContainer);
