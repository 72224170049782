import ImageUploader from "../ImageUploader";
import React, { useState } from "react";
import { Popup } from "devextreme-react/popup";
import PropsType from "prop-types";
import { FILE_URL } from "../../utils/constants";
import { Button } from "devextreme-react/button";

export default function ImageUploadPopup({ visible, hidePopup, insertImage }) {
  const [value, setValue] = useState("");

  const onImgUrlChange = (e) => {
    setValue(`${FILE_URL}${e.path}`);
  };

  const selectImage = () => {
    insertImage(value);

    hidePopup();
  };

  return (
    <Popup
      visible={visible}
      onHiding={hidePopup}
      dragEnabled={false}
      closeOnOutsideClick={false}
      showCloseButton={true}
      showTitle={true}
      title="Chọn ảnh"
      container=".widget-container"
      width={600}
      height={400}
    >
      <ImageUploader onImgUrlChange={onImgUrlChange} />
      <Button
        width={120}
        text="Chọn ảnh"
        type="default"
        stylingMode="contained"
        onClick={selectImage}
      />
    </Popup>
  );
}

ImageUploadPopup.propTypes = {
  visible: PropsType.bool,
  hidePopup: PropsType.func,
  insertImage: PropsType.func,
};
