import React, { useRef, useState } from "react";
import {
  TreeList,
  Editing,
  Column,
  Button,
  ValidationRule,
  Lookup,
  Form,
  Popup,
  GroupItem,
  SearchPanel,
} from "devextreme-react/tree-list";
import { connect } from "react-redux";
import PropsType from "prop-types";
import {
  BASE_URL,
  FILE_URL,
  GROUP_FILTER,
  GROUP_FILTER_TYPE,
  GROUP_TYPE,
  VERSION,
} from "../utils/constants";
import { formatMessage } from "devextreme/localization";
import "../styles/banner-container.scss";
import ImageUploader from "../components/ImageUploader";
import HTMLEditor from "../components/HTMLEditor";
import { Item } from "devextreme-react/form";
import useCustomStore from "../hook/useCustomStore";
import { useHistory } from "react-router-dom";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";

const URL = `${BASE_URL}${VERSION}group`;
const groupsData = useCustomStore(URL, {
  key: "id",
  take: 500,
});

const GroupContainer = ({ token }) => {
  const treeListRef = useRef(null);
  const history = useHistory();
  const [filterStatus, setFilterStatus] = useState();

  groupsData.setOptions({
    token: token,
    filter: { type: { in: GROUP_FILTER_TYPE } },
    include: ["thumbnail"],
  });

  const imgCellRender = (data) => {
    if (!data.value) {
      return <div></div>;
    }

    return (
      <img
        style={{ height: 30 }}
        src={`${FILE_URL}${data.value.responsive["180x"]}`}
      />
    );
  };

  const imgEditCellRender = (cell) => {
    return (
      <ImageUploader
        defaultValue={cell.value}
        onImgUrlChange={(value) => cell.setValue(value)}
        token={token}
      />
    );
  };

  const selectBoxGroup = {
    items: GROUP_FILTER,
    displayExpr: "name",
    valueExpr: "value",
    value: filterStatus,
    width: 250,
    placeholder: "Loại",
    showClearButton: true,
    onValueChanged: (args) => {
      const treeList = treeListRef.current.instance;

      if (!args.value) {
        treeList.clearFilter();
      } else {
        if (Array.isArray(args.value)) {
          let _filter = [];
          args.value.forEach((element) => {
            _filter.push(["type", "=", element]);
            _filter.push("or");
          });
          _filter.pop();
          treeList.filter(_filter);
        } else {
          treeList.filter(["type", "=", args.value]);
        }
      }

      setFilterStatus(args.value);
    },
  };

  const onToolbarPreparing = (e) => {
    const toolbarItems = e.toolbarOptions.items;

    // Modifies an existing item
    toolbarItems.forEach(function (item) {
      if (item.name === "addRowButton") {
        item.options = {
          icon: "plus",
          onClick: function () {
            history.push("/group/add");
          },
        };
      }
    });

    toolbarItems.unshift(
      {
        location: "after",
        widget: "dxButton",
        locateInMenu: "auto",
        options: {
          icon: "refresh",
          onClick: () => treeListRef.current.instance.refresh(),
        },
      },
      {
        location: "before",
        widget: "dxSelectBox",
        locateInMenu: "auto",
        options: selectBoxGroup,
      }
    );
  };

  const descriptionHtmlEditCellRender = (cell) => {
    return (
      <HTMLEditor
        defaultValue={cell.value}
        height={"300px"}
        onValueChange={(value) => cell.setValue(value)}
      />
    );
  };

  const onRowDblClick = (e) => {
    history.push(`/group/${e.data.id}`);
  };

  const viewButtonClick = (e) => {
    history.push(`/group/${e.row.key}`);
  };

  return (
    <>
      <div className="bg-light-blue-500 px-3 md:px-8 h-40" />

      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <CardHeader color="purple" contentPosition="none">
                <div className="w-full flex items-center justify-between">
                  <h2 className="text-white text-2xl">Quản lý Nhóm</h2>
                </div>
              </CardHeader>
              <CardBody>
                <TreeList
                  id="id"
                  parentIdExpr="parentId"
                  ref={treeListRef}
                  showBorders={true}
                  dataSource={groupsData.store}
                  autoExpandAll={false}
                  selection={{ mode: "single" }}
                  allowColumnResizing={true}
                  onRowDblClick={onRowDblClick}
                  onToolbarPreparing={onToolbarPreparing}
                >
                  <SearchPanel visible={true} />
                  <Editing
                    refreshMode={"reshape"}
                    mode="popup"
                    useIcons={true}
                    allowAdding={true}
                    allowDeleting={true}
                    allowUpdating={true}
                  >
                    <Popup title="Post Info" showTitle={true} />
                    <Form colCount={2}>
                      <GroupItem>
                        <Item dataField="name" />
                        <Item dataField="key" />
                        <Item dataField="parentId" />
                        <Item dataField="thumbnail" />
                      </GroupItem>

                      <GroupItem>
                        <Item dataField="type" />
                        <Item dataField="description" />
                      </GroupItem>
                    </Form>
                  </Editing>

                  <Column dataField="name" caption={formatMessage("name")}>
                    <ValidationRule type="required" />
                  </Column>
                  <Column dataField="key" caption={formatMessage("key")} />
                  <Column
                    visible={false}
                    dataField="parentId"
                    caption={formatMessage("parent")}
                  >
                    <Lookup
                      dataSource={groupsData}
                      valueExpr="id"
                      displayExpr="name"
                    />
                  </Column>
                  <Column
                    dataField="description"
                    caption={formatMessage("description")}
                    editCellRender={descriptionHtmlEditCellRender}
                  />
                  <Column
                    dataField="homepage"
                    caption={formatMessage("isHomePage")}
                    dataType="boolean"
                  />
                  <Column
                    dataField="type"
                    caption={formatMessage("type")}
                    dataType="number"
                  >
                    <Lookup
                      dataSource={GROUP_TYPE}
                      valueExpr="value"
                      displayExpr="name"
                    />
                  </Column>

                  <Column
                    dataField="thumbnail"
                    caption={formatMessage("thumbnail")}
                    allowSorting={false}
                    cellRender={imgCellRender}
                    editCellRender={imgEditCellRender}
                  />

                  <Column type="buttons">
                    <Button name="edit" onClick={viewButtonClick} />
                    <Button name="delete" />
                  </Column>
                </TreeList>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.data.token,
  };
};

GroupContainer.propTypes = {
  token: PropsType.string,
};

export default connect(mapStateToProps, null)(GroupContainer);
