import React from "react";
import { Switch, Redirect } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
// import NavigationDrawer from "../layout/NavigationDrawer";
import DashboardContainer from "../containers/DashboardContainer";
import ExpiredTokenContainer from "../containers/ExpiredTokenContainer";
import ProfileContainer from "../containers/ProfileContainer";
import PostContainer from "../containers/PostContainer";
import PostDetailContainer from "../containers/PostDetailContainer";
import ProductContainer from "../containers/ProductContainer";
import ProductDetailContainer from "../containers/ProductDetailContainer";
import CategoryDetailContainer from "../containers/CategoryDetailContainer";
import GroupDetailContainer from "../containers/GroupDetailContainer";
import OrderContainer from "../containers/OrderContainer";
import PromotionContainer from "../containers/PromotionContainer";
import PromotionDetailContainer from "../containers/PromotionDetailContainer";
// import UserContainer from "../containers/UserContainer";
import Sidebar from "../components/tailwind/Sidebar";
import CategoryContainer from "../containers/CategoryContainer";
import GroupContainer from "../containers/GroupContainer";
import BrandContainer from "../containers/BrandContainer";
import SettingContainter from "../containers/SettingContainter";
import BannerContainer from "../containers/BannerContainer";
import VideoContainer from "../containers/VideoContainer";
import FeedbackContainer from "../containers/FeedbackContainer";
import FeedbackDetailContainer from "../containers/FeedbackDetailContainer";
import CommentContainer from "../containers/CommentContainer";
import SeoContainer from "../containers/SeoContainer";
import PropsType from "prop-types";
import AccountContainer from "../containers/AccountContainer";

const Content = ({ roles }) => {
  const rolesList = roles.map((x) => x.name);
  const hasAdminPermission = rolesList.includes("administrator");
  const hasProductPermission =
    hasAdminPermission || rolesList.includes("product-manager");
  const hasEditorPermission =
    hasAdminPermission || rolesList.includes("post-editor");
  const hasOrderPermission =
    hasAdminPermission || rolesList.includes("order-manager");
  const hasMarketingPermission =
    hasAdminPermission || rolesList.includes("marketing");

  return (
    <>
      <Sidebar
        permision={{
          hasAdminPermission,
          hasProductPermission,
          hasEditorPermission,
          hasOrderPermission,
          hasMarketingPermission,
        }}
      />
      <div className="md:ml-64">
        {/* <NavigationDrawer title={"Anyway"}> */}

        <Switch>
          <PrivateRoute
            hasPermision={hasAdminPermission || hasOrderPermission}
            exact
            path="/"
            component={DashboardContainer}
          />
          <PrivateRoute
            exact
            path="/product"
            hasPermision={hasProductPermission || hasMarketingPermission}
            component={ProductContainer}
          />
          {/* <PrivateRoute
            exact
            path="/product/add"
            hasPermision={hasProductPermission || hasMarketingPermission}
            component={ProductDetailContainer}
          /> */}
          <PrivateRoute
            exact
            hasPermision={hasProductPermission || hasMarketingPermission}
            path="/product/:id"
            component={ProductDetailContainer}
          />
          <PrivateRoute
            path="/banner"
            hasPermision={true}
            component={BannerContainer}
          />
          <PrivateRoute
            path="/video"
            hasPermision={true}
            component={VideoContainer}
          />
          <PrivateRoute
            path="/setting"
            hasPermision={true}
            component={SettingContainter}
          />
          <PrivateRoute
            path="/comment"
            hasPermision={true}
            component={CommentContainer}
          />
          <PrivateRoute
            path="/seo"
            hasPermision={hasMarketingPermission}
            component={SeoContainer}
          />

          <PrivateRoute
            exact
            path="/brand"
            hasPermision={true}
            component={BrandContainer}
          />

          <PrivateRoute
            exact
            path="/category"
            hasPermision={true}
            component={CategoryContainer}
          />
          <PrivateRoute
            exact
            path="/category/add"
            hasPermision={true}
            component={CategoryDetailContainer}
          />
          <PrivateRoute
            exact
            path="/category/:id"
            hasPermision={true}
            component={CategoryDetailContainer}
          />

          <PrivateRoute
            exact
            path="/group"
            hasPermision={true}
            component={GroupContainer}
          />
          <PrivateRoute
            exact
            path="/group/add"
            hasPermision={true}
            component={GroupDetailContainer}
          />
          <PrivateRoute
            exact
            path="/group/:id"
            hasPermision={true}
            component={GroupDetailContainer}
          />

          <PrivateRoute
            path="/order"
            hasPermision={hasOrderPermission}
            component={OrderContainer}
          />

          <PrivateRoute
            exact
            path="/post"
            hasPermision={hasEditorPermission || hasMarketingPermission}
            component={PostContainer}
          />
          <PrivateRoute
            exact
            path="/post/add"
            hasPermision={hasEditorPermission || hasMarketingPermission}
            component={PostDetailContainer}
          />
          <PrivateRoute
            exact
            path="/post/:id"
            hasPermision={hasEditorPermission || hasMarketingPermission}
            component={PostDetailContainer}
          />

          <PrivateRoute
            exact
            path="/feedback"
            hasPermision={true}
            component={FeedbackContainer}
          />
          <PrivateRoute
            exact
            path="/feedback/add"
            hasPermision={true}
            component={FeedbackDetailContainer}
          />
          <PrivateRoute
            exact
            path="/feedback/:feedbackId"
            hasPermision={true}
            component={FeedbackDetailContainer}
          />

          <PrivateRoute
            exact
            hasPermision={true}
            path="/promotion"
            component={PromotionContainer}
          />
          <PrivateRoute
            exact
            hasPermision={true}
            path="/promotion/add"
            component={PromotionDetailContainer}
          />
          <PrivateRoute
            exact
            hasPermision={true}
            path="/promotion/:promotionId"
            component={PromotionDetailContainer}
          />
          {/* <PrivateRoute
            hasPermision={hasAdminPermission}
            path="/user"
            component={UserContainer}
          /> */}
          <PrivateRoute
            hasPermision={hasAdminPermission}
            path="/account"
            component={AccountContainer}
          />
          <PrivateRoute
            hasPermision={true}
            path="/profile"
            component={ProfileContainer}
          />
          <PrivateRoute
            path="/expiredToken"
            hasPermision={true}
            component={ExpiredTokenContainer}
          />
          <Redirect to={"/"} />
        </Switch>

        {/* </NavigationDrawer> */}
      </div>
    </>
  );
};

Content.propTypes = {
  roles: PropsType.array,
};

export default Content;
