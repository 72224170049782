import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  AUTH_CHECK_OK,
  AUTH_CHECK_FAIL,
  AUTH_CLEAR_TOKEN,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  REFRESH_REQUEST,
  REFRESH_SUCCESS,
  REFRESH_FAILURE,
} from "../actions/authActions";

var cookie = require("cookie-cutter");
var offset = new Date().getTimezoneOffset();

const INITIAL_STATE = {
  data: {
    isAuthenticated: false,
    token: "",
    refresh_token: "",
  },
  error: null,
  loading: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return {
        ...state,
        data: {
          isAuthenticated: false,
          token: "",
          refresh_token: "",
        },
        error: null,
        loading: true,
      };
    }
    case LOGIN_SUCCESS: {
      const { access_token, refresh_token, expires_in, refreshExp } =
        action.payload;

      cookie.set("token", access_token, {
        expires: new Date((expires_in + offset * 60) * 1000),
        path: "/",
      });

      cookie.set("refresh_token", refresh_token, {
        expires: new Date((refreshExp + offset * 60) * 1000),
        path: "/",
      });

      return {
        ...state,
        data: {
          isAuthenticated: true,
          token: access_token,
          refresh_token: refresh_token,
        },
        error: null,
        loading: false,
      };
    }
    case LOGIN_FAILURE: {
      return {
        ...state,
        data: {
          isAuthenticated: false,
          token: "",
          refresh_token: "",
        },
        error: action.payload.error,
        loading: false,
      };
    }

    case AUTH_CHECK_OK: {
      return {
        ...state,
        data: {
          isAuthenticated: true,
          token: action.payload.access_token,
          refresh_token: action.payload.refresh_token,
        },
        error: null,
        loading: false,
      };
    }

    case AUTH_CHECK_FAIL: {
      return {
        ...state,
        data: {
          isAuthenticated: false,
          token: null,
          refresh_token: null,
        },
        error: null,
        loading: false,
      };
    }

    case AUTH_CLEAR_TOKEN: {
      cookie.set("token", "", { expires: new Date(0) });
      cookie.set("refresh_token", "", { expires: new Date(0) });
      localStorage.clear();

      return {
        ...state,
        data: {
          isAuthenticated: false,
          token: "",
          refresh_token: "",
        },
        error: null,
        loading: false,
      };
    }

    case LOGOUT_REQUEST: {
      return {
        ...state,
        error: null,
        loading: true,
      };
    }
    case LOGOUT_SUCCESS: {
      cookie.set("token", "", { expires: new Date(0) });
      cookie.set("refresh_token", "", { expires: new Date(0) });
      localStorage.clear();
      return {
        ...state,
        data: {
          isAuthenticated: false,
          token: "",
          refresh_token: "",
        },
        error: null,
        loading: false,
      };
    }
    case LOGOUT_FAILURE: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }

    case REFRESH_REQUEST: {
      return {
        ...state,
        error: null,
        loading: true,
      };
    }
    case REFRESH_SUCCESS: {
      const { access_token, refresh_token, expires_in, refreshExp } =
        action.payload;
      cookie.set("token", access_token, {
        expires: new Date((expires_in + offset * 60) * 1000),
        path: "/",
      });

      cookie.set("refresh_token", refresh_token, {
        expires: new Date((refreshExp + offset * 60) * 1000),
        path: "/",
      });

      return {
        ...state,
        data: {
          isAuthenticated: true,
          token: access_token,
          refresh_token: refresh_token,
        },
        error: null,
        loading: false,
      };
    }
    case REFRESH_FAILURE: {
      return {
        ...state,
        data: {
          isAuthenticated: false,
          token: "",
          refresh_token: "",
        },
        error: action.payload.error,
        loading: false,
      };
    }

    default:
      return state;
  }
};

export default reducer;
